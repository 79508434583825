import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/shutterstock_1685806537.jpg'
import styles from './post-grid.module.css'

const HowToEatOnTheWeekend = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Jak jíst o víkendu'}
        description={
          'Přes týden odebíráte krabičkovou dietu a nevíte si rady s tím, jak jíst o víkendu? Mám pro vás několik jednoduchých tipů, kterých se držet, abyste zvládli zdravé stravování i tehdy, nemáte-li po ruce naše krabičky!'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/how-to-eat-on-the-weekend">
            Jak jíst o víkendu
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Jak jíst o víkendu"
              date="05.06.2023"
            />
            <div>
              <p className={styles.postText}>
                Přes týden odebíráte krabičkovou dietu a <b>nevíte si rady s tím, jak jíst o víkendu</b>? Mám pro vás několik jednoduchých tipů, kterých se držet, abyste zvládli <b>zdravé stravování</b> i tehdy, nemáte-li po ruce {' '}
                <a
                  href="https://nutritionpro.cz"
                  target="_blank"
                >
                  naše krabičky
                </a>
                <b>!</b>
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />

              <h5 className={styles.postTitle}>1. Plánujte si jídla dopředu</h5>


              <p className={styles.postText}>
                Zkuste si <b>naplánovat jídla na celý víkend dopředu</b> a připravte si potřebné ingredience v předstihu. Plánování je při zdravém stravování velmi důležité. Pokud budete mít <b>pravidelný a dostatečný přísun živin</b>, nebudete mít přes den hlad ani chutě. Nenastane tak situace, kdy byste šli a vyjedli celou ledničku. Předejdete také tomu, že byste museli rychle řešit, co si dát a sáhli po nevhodné variantě, například nějakém fastfoodu. Plánování jídel dopředu vám pomůže <b>udržet se v režimu</b>, i když zrovna nemáte krabičky.
              </p>

              <p className={styles.postText}>
                Jestli máte v plánu nějakou oslavu nebo párty, <b>zohledněte ji ve svém stravovacím plánu</b>. Pokud si plánujete dát dort a skleničku, dopředu se zamyslete nad tím, co budete jíst a pít po zbytek týdne. V určité míře si totiž můžete dát, co chcete, musíte se ale zamyslet nad <b>celkovou skladbou jídelníčku</b> a s ničím to nepřehánět.
              </p>

              <p className={styles.postText}>
                Pokud se chystáte někam na výlet, <b>připravte si jídlo s sebou!</b> Můžete si připravit například celozrnné pečivo s lučinou, šunkou, sýrem a zeleninou. Nezapomínejte ani na <b>svačinky</b>, které zajistí, že nebudete mít hlad mezi hlavními chody. Ty může tvořit například ovoce, ořechy a mléčné výrobky.
              </p>

              <h5 className={styles.postTitle}>2. Pozor na jídla v restauracích
              </h5>
              <p className={styles.postText}>
                Pokud vyrazíte na večeři nebo si třeba objednáte jídlo s přáteli, <b>zamyslete se nad tím, co si dáváte</b>. Jídlo by mělo obsahovat <b>dostatek bílkovin, komplexních sacharidů a zdravé tuky</b>. Také by <b>neměla chybět zelenina</b>, pokud tedy není součástí vašeho jídla, dejte si navíc salát. <b>Nebojte se příloh</b>, obsahují již zmíněné komplexní sacharidy, které též potřebujeme. <b>Polévky </b>
                jsou dobrou volbou před hlavním jídlem, nenahrazují ho však. Obvykle neobsahují dostatečné množství energie a také mohou úplně postrádat zdroj bílkovin. <b>Těžší a tučnější</b> budou různé smažené pokrmy, omáčky, ale i některé zálivky. Pokud se po nich necítíte dobře nebo máte <b>nastavený stravovací plán</b> a snažíte se <b>vylepšit své stravovací návyky</b>, raději zvolte nějakou lehčí variantu. Co se týká porce, pokud máte krabičky, už byste zhruba
                <b> měli umět odhadnout, jak by měla vaše porce vypadat.</b> Nenuťte se do jídla, pokud už nemůžete nebo je jídlo větší, než jste normálně zvyklí.
              </p>

              <p className={styles.postText}>
                Myslete také na to, že pokud si zajdete jednou týdně do restaurace a chcete si dát něco, na co máte celý týden chuť, v klidu si to <b>můžete dát</b>, pokud se po zbytek týdne stravujete zdravě. <b>Vše je o rovnováze</b>.
              </p>

              <h5 className={styles.postTitle}>3. Zkuste nové recepty</h5>
              <p className={styles.postText}>Pokud máte čas, zkuste si najít nové, zdravé recepty a <b>vyzkoušejte si podle nich uvařit.</b> Víkend je ideální příležitostí! Díky tomu můžete <b>zdokonalit své kulinářské schopnosti</b> a poznat nové suroviny a pokrmy. Bude to nejen <b>zábavné</b>, ale také vás to <b>namotivuje </b> k dodržování zdravé životosprávy.</p>

              <h5 className={styles.postTitle}>4. Nenechte se ovlivnit ostatními</h5>
              <p className={styles.postText}>Když jde o stravování, <b>ostatní lidé mohou mít velký vliv na to, co jíte</b> a kolik jíte. Například když jdete na večeři s přáteli, může se stát, že se rozhodnete <b>objednat něco jiného nebo větší množství</b>, než byste normálně chtěli, protože si nechcete připadat lakotně nebo nechcete poslouchat hloupé řeči. To, co jíte, může také z velké části ovlivňovat vaše rodina, která chce třeba jíst něco jiného, než vy.
              </p>

              <p className={styles.postText}>Je důležité si uvědomit, že každý má <b>jiné stravovací návyky a preference</b> a že není nic špatného na tom, když si dáte menší porci jídla nebo když se rozhodnete vybrat si něco zdravějšího. Pokud se budete řídit svými cíli a potřebami, ušetříte si stres a nebudete litovat svých rozhodnutí. <b>Stůjte si za svým! </b>
              </p>

              <p className={styles.postText}><b>Nenechte se ovlivnit ostatními a buďte věrni svým cílům a potřebám</b>. Snažte se najít <b>rovnováhu</b>  mezi užíváním si společnosti ostatních lidí a udržováním svého zdraví a pohody.
              </p>

              <p className={styles.postText}>Doufám, že vám tyto tipy pomohou s víkendovým stravováním a užijete si ho stejně tak, jako si užíváte krabičky ve všední dny!
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default HowToEatOnTheWeekend
